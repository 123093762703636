import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./OurApproach.module.scss"
import staric from "../../images/ai-development-page/staric.svg"
import blueStaric from "../../images/ai-development-page/blueStaric.svg"

const OurApproach = ({ strapiData }) => {
  const [hovering, setHovering] = useState(false)

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.bgRemove}>
        <Container>
          <div>
            <h2
              className={styles.subTitle}
              dangerouslySetInnerHTML={{
                __html: strapiData?.title,
              }}
            />
            <p
              className={styles.desc}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
          </div>
        </Container>
      </div>
      <div className={styles.aiPoweredCard}>
        {strapiData?.cards?.map((el, ind) => (
          <div
            onMouseOver={() => setHovering(ind)}
            onMouseLeave={() => setHovering(false)}
            className={styles.cardContent}
            key={`approach_${el?.title}`}
          >
            {" "}
            <Container>
              <Row className={styles.innerRow}>
                <Col xs={12} sm={12} md={12} lg={1}>
                  <img
                    src={
                      hovering === ind
                        ? el?.image2?.[0]?.localFile?.publicURL ||
                          el?.image1?.[0]?.localFile?.publicURL
                        : el?.image1?.[0]?.localFile?.publicURL
                    }
                    alt={"make smarter"}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <h4 className={styles.cardHeading}>
                    <span>{el?.title}</span>
                    <br />
                  </h4>
                </Col>
                <Col xs={12} sm={12} md={12} lg={1} className={styles.star}>
                  <img
                    src={hovering === ind ? staric : blueStaric}
                    alt={"make smarter"}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <p className={styles.cardDescription}>{el?.subTitle}</p>
                </Col>
              </Row>
            </Container>{" "}
          </div>
        ))}
      </div>
    </div>
  )
}

export default OurApproach
