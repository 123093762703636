// extracted by mini-css-extract-plugin
export var SliderWidth = "WordsFromClients-module--SliderWidth--66a88";
export var Trust = "WordsFromClients-module--Trust--965a5";
export var blueText = "WordsFromClients-module--blueText--db49c";
export var clientContent = "WordsFromClients-module--clientContent--e59c8";
export var coma = "WordsFromClients-module--coma--84fd4";
export var content = "WordsFromClients-module--content--451fb";
export var customModal = "WordsFromClients-module--customModal--f958b";
export var dec = "WordsFromClients-module--dec--a42c2";
export var description = "WordsFromClients-module--description--04592";
export var designation = "WordsFromClients-module--designation--241fc";
export var dot = "WordsFromClients-module--dot--fa916";
export var heading = "WordsFromClients-module--heading--80562";
export var headingContainer = "WordsFromClients-module--headingContainer--19521";
export var iconContainer = "WordsFromClients-module--iconContainer--a8fcc";
export var iconContainerLeft = "WordsFromClients-module--iconContainerLeft--9e240";
export var logos = "WordsFromClients-module--logos--7aad8";
export var logosBg = "WordsFromClients-module--logosBg--c829f";
export var name = "WordsFromClients-module--name--e024e";
export var playBtn = "WordsFromClients-module--playBtn--dc4e3";
export var portfolioArrowIcon = "WordsFromClients-module--portfolioArrowIcon--98405";
export var portfolioArrowIconCover = "WordsFromClients-module--portfolioArrowIconCover--258ea";
export var portfolioArrowRightIconCover = "WordsFromClients-module--portfolioArrowRightIconCover--85256";
export var view = "WordsFromClients-module--view--c40f2";