// extracted by mini-css-extract-plugin
export var aiPoweredCard = "OurApproach-module--aiPoweredCard--6bd17";
export var cardContent = "OurApproach-module--cardContent--fcb35";
export var cardDescription = "OurApproach-module--cardDescription--ee841";
export var cardHeading = "OurApproach-module--cardHeading--622ce";
export var counting = "OurApproach-module--counting--d7832";
export var desc = "OurApproach-module--desc--aa7cd";
export var innerRow = "OurApproach-module--innerRow--192b3";
export var keyPoint = "OurApproach-module--keyPoint--7318a";
export var keyPoints = "OurApproach-module--keyPoints--2eaf4";
export var sectionContainer = "OurApproach-module--sectionContainer--76d10";
export var star = "OurApproach-module--star--27dd3";
export var subTitle = "OurApproach-module--subTitle--da219";
export var title = "OurApproach-module--title--d4ea2";
export var transitionEffect = "OurApproach-module--transitionEffect--f5d45";