// extracted by mini-css-extract-plugin
export var arrowAnimation = "LongTerm-module--arrowAnimation--552c2";
export var black = "LongTerm-module--black--dafd3";
export var blue = "LongTerm-module--blue--4276f";
export var card = "LongTerm-module--card--3c03f";
export var card1 = "LongTerm-module--card1--a3a32";
export var card2 = "LongTerm-module--card2--14ef7";
export var card3 = "LongTerm-module--card3--fcbb4";
export var colGap = "LongTerm-module--colGap--f484d";
export var concerns = "LongTerm-module--concerns--051cd";
export var description = "LongTerm-module--description--bd0b0";
export var heading = "LongTerm-module--heading--77779";
export var newBtn = "LongTerm-module--newBtn--ba591";
export var progress = "LongTerm-module--progress--c9e45";
export var progressSec = "LongTerm-module--progressSec--df5b5";
export var subDescription = "LongTerm-module--subDescription--5c77f";
export var subHeading = "LongTerm-module--subHeading--e646d";
export var talkBtn = "LongTerm-module--talkBtn--ba8de";
export var white = "LongTerm-module--white--06bf2";