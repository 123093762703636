// extracted by mini-css-extract-plugin
export var aiPoweredCard = "SweetSpot-module--aiPoweredCard--60e00";
export var cardContent = "SweetSpot-module--cardContent--b6215";
export var cardDescription = "SweetSpot-module--cardDescription--132c4";
export var counting = "SweetSpot-module--counting--57ffa";
export var desc = "SweetSpot-module--desc--99791";
export var headingContainer = "SweetSpot-module--headingContainer--416b0";
export var keyPoint = "SweetSpot-module--keyPoint--a92e2";
export var keyPoints = "SweetSpot-module--keyPoints--14416";
export var sectionContainer = "SweetSpot-module--sectionContainer--90b3f";
export var subTitle = "SweetSpot-module--subTitle--c8e5e";
export var title = "SweetSpot-module--title--fb8ab";