// extracted by mini-css-extract-plugin
export var Trust = "WorkPortfolio-module--Trust--869a4";
export var arrowIcon = "WorkPortfolio-module--arrowIcon--9ea15";
export var arrowImage = "WorkPortfolio-module--arrowImage--7f928";
export var arrowLink = "WorkPortfolio-module--arrowLink--b09d4";
export var btn = "WorkPortfolio-module--btn--4e7fb";
export var btn2 = "WorkPortfolio-module--btn2--f3b87";
export var caseImages = "WorkPortfolio-module--caseImages--6b854";
export var clientImgs = "WorkPortfolio-module--clientImgs--b40d0";
export var content = "WorkPortfolio-module--content--9b87f";
export var description = "WorkPortfolio-module--description--3309e";
export var headSearch = "WorkPortfolio-module--headSearch--7d208";
export var heading = "WorkPortfolio-module--heading--aba19";
export var logoImages = "WorkPortfolio-module--logoImages--03d96";
export var logos = "WorkPortfolio-module--logos--1e932";
export var rowGap = "WorkPortfolio-module--rowGap--a381e";
export var talkBtn = "WorkPortfolio-module--talkBtn--1b1ce";