// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--ef16e";
export var backgroundImg = "Banner-module--backgroundImg--0bcdc";
export var bannerCurve = "Banner-module--bannerCurve--5ea30";
export var bannerHeading = "Banner-module--bannerHeading--265b2";
export var btn = "Banner-module--btn--47196";
export var btne = "Banner-module--btne--fef26";
export var curve = "Banner-module--curve--2cb28";
export var dedicated = "Banner-module--dedicated--a8561";
export var dedicatedBanner = "Banner-module--dedicatedBanner--adeae";
export var dedicatedHeading = "Banner-module--dedicatedHeading--0acb4";
export var dedicatedHeadingStatic = "Banner-module--dedicatedHeadingStatic--8411d";
export var description = "Banner-module--description--16fd0";
export var innerDedicated = "Banner-module--innerDedicated--65259";
export var newBtn = "Banner-module--newBtn--1a922";
export var sliderButtons = "Banner-module--sliderButtons--4ec96";
export var subTitle = "Banner-module--subTitle--603af";