// extracted by mini-css-extract-plugin
export var card = "AIExpertiseSection-module--card--ff1f7";
export var cardCounting = "AIExpertiseSection-module--cardCounting--29754";
export var cardHeading = "AIExpertiseSection-module--cardHeading--922fb";
export var cardsContainer = "AIExpertiseSection-module--cardsContainer--4964d";
export var cta = "AIExpertiseSection-module--cta--994f9";
export var description = "AIExpertiseSection-module--description--4469e";
export var heading = "AIExpertiseSection-module--heading--4ee84";
export var highlight = "AIExpertiseSection-module--highlight--fcfc2";
export var imgContainer = "AIExpertiseSection-module--imgContainer--c5b3c";
export var sectionContainer = "AIExpertiseSection-module--sectionContainer--f8c99";