import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import MainLayout from "../../layouts/MainLayout3"
import Clients from "../../components/ai-development-services-1/Clients"
import BannerSlider from "../../components/ai-development-services-1/BannerSlider"
import AIExpertiseSection from "../../components/ai-development-services-1/AIExpertiseSection"
import TrustInvoZone from "../../components/home-sections/ClientLogos"
import AILaunchpad from "../../components/ai-development-services-1/AILaunchpad"
import MakeSmarter from "../../components/ai-development-services-1/MakeSmarter"
import InstantRecap from "../../components/ai-development-services-1/InstantRecap"
import AIPowered from "../../components/ai-development-services-1/SweetSpot"
import OurApproach from "../../components/ai-development-services-1/OurApproach"
import BenefitsOfWorking from "../../components/ai-development-services-1/BenifitsOfWorking"
import LongTerm from "../../components/ai-development-services-1/LongTerm"
import WorkPortfolio from "../../components/ai-development-services-1/WorkPortfolio"
import WordsFromClients from "../../components/ai-development-services-1/WordsFromClients"
import SEORevamp from "../../components/common/SEO_Revamp"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}

const AIDevelopment = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const aiExpertise = data?.strapiPage?.sections[1]
  const aiPowered = data?.strapiPage?.sections[2]
  const launchPad = data?.strapiPage?.sections[3]
  const smarterDecision = data?.strapiPage?.sections[4]
  const recap = data?.strapiPage?.sections[5]
  const ourApproach = data?.strapiPage?.sections[6]
  const businessSector = data?.strapiPage?.sections[7]
  const benefits = data?.strapiPage?.sections[8]
  const longTerm = data?.strapiPage?.sections[9]
  const ourPortfolio = data?.strapiPage?.sections[10]
  const wordsFromClient = data?.strapiPage?.sections[11]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={true} schemas={bodySchema}>
      <BannerSlider
        strapiData={heroSection}
        breadCrumb={data?.strapiPage?.buttons}
      />
      <TrustInvoZone hideCurve={true} />
      <AIExpertiseSection strapiData={aiExpertise} />
      <AIPowered strapiData={aiPowered} />
      <AILaunchpad strapiData={launchPad} />
      <MakeSmarter strapiData={smarterDecision} />
      <InstantRecap strapiData={recap} />
      <OurApproach strapiData={ourApproach} />
      <Clients strapiData={businessSector} />
      <BenefitsOfWorking strapiData={benefits} />
      <LongTerm strapiData={longTerm} />
      <WorkPortfolio strapiData={ourPortfolio} />
      <WordsFromClients strapiData={wordsFromClient} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "ai-development-services-1" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          jsonData {
            aiPowered {
              services {
                serLink
                serName
              }
            }
          }
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default AIDevelopment

// export const Head = ({ data }) => {
//   const { metaTitle, metaDescription } = data?.strapiPage?.seo
//   let headSchema = []
//   const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
//     return v?.visibilityIn === true ? v : headSchema?.push(v) && false
//   })
//   return (
//     <SEORevamp
//       title={metaTitle}
//       description={metaDescription}
//       schemas={headSchema}
//       image="https://invozone-backend.s3.amazonaws.com/staff_augmentation_company_7031a2796c.svg"
//     />
//   )
// }
