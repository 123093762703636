// extracted by mini-css-extract-plugin
export var SliderWidth = "Clients-module--SliderWidth--a028f";
export var Trust = "Clients-module--Trust--59e89";
export var arrowPoint = "Clients-module--arrowPoint--b6892";
export var blurInOut = "Clients-module--blurInOut--08115";
export var buton = "Clients-module--buton--f56c8";
export var cardContent = "Clients-module--cardContent--b25dd";
export var cardFooter = "Clients-module--cardFooter--64ad1";
export var cardHeading = "Clients-module--cardHeading--9d03f";
export var cardHover = "Clients-module--cardHover--bf73d";
export var cards = "Clients-module--cards--5b863";
export var description = "Clients-module--description--e1bef";
export var greyCard = "Clients-module--greyCard--6a1d6";
export var heading = "Clients-module--heading--2cc85";
export var headingAndDesc = "Clients-module--headingAndDesc--85736";
export var iconContainer = "Clients-module--iconContainer--c88bf";
export var iconContainerLeft = "Clients-module--iconContainerLeft--7ac20";
export var image2 = "Clients-module--image2--7b982";
export var indDec = "Clients-module--indDec--f8b5f";
export var newBtn = "Clients-module--newBtn--26bf2";
export var points = "Clients-module--points--f871e";
export var portfolioArrowIcon = "Clients-module--portfolioArrowIcon--2c811";
export var portfolioArrowIconCover = "Clients-module--portfolioArrowIconCover--75eff";
export var portfolioArrowRightIconCover = "Clients-module--portfolioArrowRightIconCover--b79e8";
export var redCard = "Clients-module--redCard--a96ef";
export var sheildImgTop = "Clients-module--sheildImgTop--4d32e";
export var shieldTopBannerImg = "Clients-module--shieldTopBannerImg--5f0d5";
export var skill = "Clients-module--skill--49943";
export var text = "Clients-module--text--dfa56";