import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Container from "react-bootstrap/Container"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import * as styles from "./Banner.module.scss"
import "./bannerDots.scss"

const BannerSlider = ({ strapiData }) => {
  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)

  useEffect(() => {
    ;(current === strapiData?.cards.length - 3 ||
      current_1 === strapiData?.cards.length - 2 ||
      current_2 === strapiData?.cards.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 5000)
  }, [current, current_1, current_2])

  const settings = {
    dots: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: true,
    infinite: false,
    beforeChange: (current, next) => setCurrent(next),
    customPaging: function (i) {
      return (
        <div
          style={{
            width: "150px",
            background: i === current ? "#217cfd" : "#F2F2F2",
            borderRadius: "4px",
            marginTop: "-210px",
            position: "absolute",
            height: "8px",
          }}
        ></div>
      )
    },
    dotsClass: "slick-dots",
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: false,
        },
      },
    ],
  }

  const videos = [
    {
      poster:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/hero_Section_Bg2_2733eb4c17.webp",
      video:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/ai_slider_video1_20bab32880.mp4",
    },
    {
      poster:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_3_823d290b67.webp",
      video:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/ai_slider_video2_772a65e80f.mp4",
    },
    {
      poster:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/ai_hero_image_3_35edee492c.webp",
      video:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/ai_slider_video3_794aeacf31.mp4",
    },
  ]

  return (
    <div className={styles.dedicatedBanner}>
      <Slider {...settings} ref={slide1} className="bannerSlider">
        {strapiData?.cards?.map((el, ind) => (
          <Container className={styles.innerDedicated} key={el?.title}>
            <div className={styles.dedicated}>
              <video
                className={styles.backgroundImg}
                autoPlay
                muted
                loop
                poster={videos?.[ind]?.poster}
              >
                <source src={videos?.[ind]?.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {/* <img src={bgImg1} alt='bg image' className={styles.backgroundImg} /> */}
              <Container>
                <div className={styles.dedicatedHeading}>
                  {ind === 0 && (
                    <h1
                      className={styles.subTitle}
                      dangerouslySetInnerHTML={{
                        __html: strapiData?.title,
                      }}
                    />
                  )}
                  {ind > 0 && (
                    <p
                      className={styles.subTitle}
                      dangerouslySetInnerHTML={{
                        __html: strapiData?.title,
                      }}
                    />
                  )}
                  {ind === 0 && (
                    <h2
                      className={styles.bannerHeading}
                      dangerouslySetInnerHTML={{
                        __html: el?.title,
                      }}
                    />
                  )}
                  {ind > 0 && (
                    <p
                      className={styles.bannerHeading}
                      dangerouslySetInnerHTML={{
                        __html: el?.title,
                      }}
                    />
                  )}

                  <p
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                      __html: el?.subTitle,
                    }}
                  />
                </div>
                <Link
                  to={el?.buttons?.[0]?.url}
                  style={{ textDecoration: "none" }}
                >
                  <div className={`blackButtonBlueArrowBg ${styles.btne}`}>
                    {el?.buttons[0] && <p>{el?.buttons?.[0]?.title}</p>}
                  </div>
                </Link>
              </Container>
            </div>
          </Container>
        ))}
      </Slider>
      <img
        className={styles.curve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
      />
    </div>
  )
}

export default BannerSlider
